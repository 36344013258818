import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 292.000000 193.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,193.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M0 965 l0 -965 1460 0 1460 0 0 965 0 965 -1460 0 -1460 0 0 -965z
m1678 113 c-5 -329 -12 -399 -39 -382 -6 3 -15 0 -20 -7 -4 -8 -13 -15 -19
-15 -5 -1 -13 -2 -16 -3 -4 0 0 -8 7 -17 12 -15 11 -16 -8 -10 -11 4 -50 15
-84 25 -121 36 -204 63 -229 77 -30 16 -37 43 -39 157 l-1 87 48 0 47 0 -3
-75 -4 -75 107 0 c119 0 164 14 184 56 19 42 14 88 -14 114 -22 21 -23 25 -10
44 17 24 19 69 5 97 -19 35 -59 46 -165 45 l-100 -1 -3 -68 -3 -68 -42 3 -42
3 -3 101 -3 101 213 70 c117 39 219 72 227 72 11 1 12 -57 9 -331z m-174 46
c9 -3 16 -16 16 -29 0 -28 -17 -35 -79 -35 l-47 0 4 35 c4 34 5 35 47 35 24 0
50 -3 59 -6z m24 -151 c6 -10 6 -28 1 -40 -7 -20 -15 -23 -68 -23 l-59 0 -4
40 -4 40 63 0 c49 0 64 -4 71 -17z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
